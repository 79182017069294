import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, createHttpLink } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from 'crypto-hash'

if (process.env.NODE_ENV === "development") {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const link = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: process.env.CACHE_GRAPHQL_RESPONSES !== "false" ? true : false,
}).concat(new HttpLink({ uri: `${process.env.NEXT_PUBLIC_WORDPRESS_API_URL}/graphql` }));

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  /*
    //If going back to Stellate, need to figure this one out and uncomment it.
    headers: {
    'gcdn-force': 1
  },*/
  defaultOptions: {
    query: {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
    }
  }
});